import { NextPage } from 'next';
import styled from "styled-components"
import Link from  'next/link';


const NotFound: NextPage = () => {

  return (<Error>
      <h2 className="title">404</h2>
      <h3 className="sub-title">Diese Seite wurde nicht gefunden!</h3>
      <p className="content">Die gesuchte Seite konnte nicht gefunden werden. Sie wurde möglicherweise entfernt, umbenannt oder existierte von vornherein nicht.</p>
      <Link href='/'>
        <a className='back-to-home'>Zurück zu home</a>
      </Link>
    </Error>);
}

export default NotFound;

const Error = styled.div`
  text-align: center;
  padding: 0px 30px 150px;
  max-width: 800px;
  margin: 0 auto;

  .title {
    font-size: 100px;
    line-height: 1;
    padding-bottom: 20px;
    margin: 0;
    font-style: normal;
    font-weight: 900;
  }

  .sub-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    font-weight: 400;
  }

  .content {
    font-size: 16px;
    font-weight: 200;
  }

  .back-to-home {
    display: inline-block;;
    margin-top: 40px;
    padding: 20px 30px;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.02em;
    border-radius: 40px;
    color: #000;
    transition: all 500ms ease;
    cursor: pointer;
    text-decoration: underline;
  }

  @media screen and (min-width: ${props => props.theme.breakPoints.desktop }) {
    .title {
      font-size: 150px;
      font-size: 10vw;
    }

    .sub-title {
      font-size: 34px;
      font-size: 1.75vw;
      margin-bottom: 30px;
    }

    .content {
      font-size: 20px;
      font-size: 1.15vw;
    }

    .back-to-home {
      font-size: 20px;
      font-size: 1.15vw;
    }
  }
`